<template>
  <div v-if="certsCallLoaded && showCertificatesPublicly" class="certificates-container">
    <h2>Certificados de {{ user_name }}</h2>
    <div class="md-layout md-gutter">
      <div class="cert-card md-layout-item md-size-50 md-pb-3">
        <div class="md-card">
          <div class="md-card-header">
            <div class="md-title">Certificado curso Básico</div>
          </div>
          <div class="md-card-content">
            <div v-if="basicCompleted">
              <div class="certificate-icon">
                <i class="material-icons md-48 md-green-600">done</i>
              </div>
              <p>El usuario ha superado los contenidos del curso básico con éxito.</p>
              <div class="md-card-actions">
                <a href="#" @click="printBasicCertificate($event)"
                  class="md-button md-raised md-primary md-bg-blue-500">Descargar
                  certificado PDF</a>
              </div>
            </div>
            <div v-else>
              <div class="certificate-icon">
                <i class="material-icons md-48 md-grey-600">lock</i>
              </div>
              <p>El usuario aún no ha superado el curso Básico.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="cert-card md-layout-item md-size-50 md-pb-3">
        <div class="md-card">
          <div class="md-card-header">
            <div class="md-title">Certificado curso Avanzado</div>
          </div>
          <div class="md-card-content">
            <div v-if="advancedCompleted">
              <div class="certificate-icon">
                <i class="material-icons md-48 md-green-600">done</i>
              </div>
              <p>El usuario ha superado los contenidos del curso Avanzado con éxito.</p>
              <div class="md-card-actions">
                <a href="#" @click="printAdvancedCertificate($event)"
                  class="md-button md-raised md-primary md-bg-blue-500">Descargar
                  certificado PDF</a>
              </div>
            </div>
            <div v-else>
              <div class="certificate-icon">
                <i class="material-icons md-48 md-grey-600">lock</i>
              </div>
              <p>El usuario aún no ha superado el curso Avanzado.</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div id="profile-fail-load" v-else-if="certsCallLoaded">
    <h1>Hay problemas cargando los certificados.<br>Por favor, inténtelo de nuevo más tarde o póngase en contacto con
      nosotros.</h1>
    <span @click="discardChanges">Intentar de nuevo</span>
    <span @click="goToStockchart">Volver al Graficador Dinámico</span>
  </div>
  <div v-else></div>
</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import axios from "axios";

export default {
  mixins: [APICaller],
  name: "Certificates",
  data() {
    return {
      uid: 0,
      certsCallLoaded: false,
      showCertificatesPublicly: false,
      basicCompleted: false,
      advancedCompleted: false,
      basicLink: false,
      advancedLink: false,
      user_name: "Cargando..."
    };
  },
  created() {
    const uidInParams = this.$route.params.uid !== undefined;
    this.uid = uidInParams ? this.$route.params.uid : localStorage.getItem("uid");

    this.loadCertificateData();
  },
  methods: {
    loadCertificateData() {
      const url = '/certificates/user-data/' + this.uid;

      const success = response => {
        const { show_certs, certs, name } = response.data;
        const { basic, advanced } = certs;

        this.showCertificatesPublicly = show_certs;
        this.user_name = name;
        this.basicCompleted = basic.passed;
        this.advancedCompleted = advanced.passed;
        this.basicLink = basic.link;
        this.advancedLink = advanced.link;
        this.certsCallLoaded = true;
      }

      const failure = response => {
        this.certsCallLoaded = true;
      }

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('370', 'Error recuperando certificados', failure);

      this._getAPICall(url, successHandler, failureHandler);
    },
    discardChanges: function () {
      location.reload();
    },
    goToStockchart() {
      this.$router.push('graficador-dinamico');
    },
    printBasicCertificate(event) {
      this.printCertificate('basic', event);
    },
    printAdvancedCertificate(event) {
      this.printCertificate('advanced', event);
    },
    printCertificate(courseType, event) {
      event.target.classList.add('loading');
      event.target.textContent = 'DESCARGANDO...';

      //APICaller doesnt currently support responseType blob, update this code when APICaller gets updated
      axios({
        url: process.env.VUE_APP_API + '/certificates/generate-certificate/' + courseType + '/' + this.uid,
        method: 'GET',
        responseType: 'blob'
      })
        .then(response => {
          event.target.classList.remove('loading');
          event.target.textContent = 'DESCARGAR CERTIFICADO PDF';

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'certificado_' + (courseType === 'basic' ? 'basico' : 'avanzado') + '.pdf');
          document.body.appendChild(link);
          link.click();
        });
    }
  },
};
</script>

<style scoped>
.certificates-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

h2 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}

.md-card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
}

.md-card:hover {
  transform: translateY(-5px);
}

.md-card-header {
  background-color: #00add9;
  color: #fff;
  padding: 1rem;
}

.md-title {
  font-size: 1.5rem;
  margin: 0;
}

.md-card-content {
  padding: 1rem;
}

.certificate-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  font-size: 4rem;
}

.md-button {
  margin-top: 1rem;
}

.md-green-600 {
  color: #4caf50;
}

.md-grey-600 {
  color: #757575;
}

.cert-card {
  margin-bottom: 25px;
}

.certificates-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: var(--color-text-primary);
}

h2 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 2rem;
}

.md-card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
}

.md-card:hover {
  transform: translateY(-5px);
}

.md-card-header {
  background-color: #00add9;
  color: #fff;
  padding: 1rem;
}

.md-title {
  font-size: 1.5rem;
  margin: 0;
}

.md-card-content {
  padding: 1rem;
  background-color: var(--color-surface);
}

.certificate-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  font-size: 4rem;
}

.md-button {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

.md-button:hover {
  background-color: #3b8a3d;
}

.md-bg-blue-500 {
  background-color: #4caf50;
}

.loading, .loading:hover {
  background-color: #757575;
}

#profile-fail-load {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  row-gap: 20px;
}

#profile-fail-load h1 {
  font-size: 20px;
}

#profile-fail-load span {
  color: #00add9;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
</style>
